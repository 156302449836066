//
// Star rating
//

.star-rating {
  &:focus-within {
    outline: $form-star-focus-outline;
    box-shadow: $form-star-focus-box-shadow;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
  }

  > label {
    display: block;
    float: left;
    width: 1em;
    height: 1em;
    font-size: $form-star-size;
    cursor: pointer;
    @include form-star-rating($accessible-orange);

    &:not(:first-of-type){
      margin-left: $form-star-margin-between;
    }
  }

  > input:checked ~ label {
    @include form-star-rating($gray-500);
  }

  > input:checked + label {
    @include form-star-rating($accessible-orange);
  }

  > input:focus ~ label {
    @include form-star-rating($gray-500);
  }

  > input:focus + label {
    @include form-star-rating($accessible-orange);
  }

  &:hover input + label {
    @include form-star-rating($black);
  }

  > input:hover ~ label {
    @include form-star-rating($gray-500);
  }

  > input:hover + label {
    @include form-star-rating($black);
  }
}

.star-rating-dark {
  &:focus-within {
    outline: $form-star-focus-outline-dark;
  }

  > label {
    @include form-star-rating($brand-orange);
  }

  > input:checked ~ label {
    @include form-star-rating($gray-700);
  }

  > input:checked + label {
    @include form-star-rating($brand-orange);
  }

  > input:focus ~ label {
    @include form-star-rating($gray-700);
  }

  > input:focus + label {
    @include form-star-rating($brand-orange);
  }

  &:hover input + label {
    @include form-star-rating($white);
  }

  > input:hover ~ label {
    @include form-star-rating($gray-700);
  }

  > input:hover + label {
    @include form-star-rating($white);
  }
}

.star-rating-sm > label {
  font-size: $form-star-size-sm;
}
