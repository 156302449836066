// scss-docs-start alert-variant-mixin
@mixin alert-variant($background, $border, $color, $background-image) { // Boosted mod
  --#{$prefix}alert-color: #{$color};
  --#{$prefix}alert-bg: #{$background};
  --#{$prefix}alert-border: #{$border};

  // Boosted mod
  .alert-icon::before {
    background-image: $background-image;
  }
  // End mod

  // Boosted mod: no $enable-gradients test

  // Boosted mod: no .alert-link
}
// scss-docs-end alert-variant-mixin
