//
// Base styles
//

.alert {
  // scss-docs-start alert-css-vars
  --#{$prefix}alert-bg: transparent;
  --#{$prefix}alert-padding-x: #{$alert-padding-x};
  --#{$prefix}alert-padding-y: #{$alert-padding-y};
  --#{$prefix}alert-margin-bottom: #{$alert-margin-bottom};
  --#{$prefix}alert-color: #{$alert-color}; // Boosted mod
  --#{$prefix}alert-border-color: transparent;
  --#{$prefix}alert-border: #{$alert-border-width} solid var(--#{$prefix}alert-border-color);
  --#{$prefix}alert-border-radius: #{$alert-border-radius};
  // scss-docs-end alert-css-vars

  position: relative;
  display: flex; // Boosted mod
  padding: var(--#{$prefix}alert-padding-y) var(--#{$prefix}alert-padding-x);
  margin-bottom: var(--#{$prefix}alert-margin-bottom);
  font-weight: $font-weight-bold; // Boosted mod
  color: var(--#{$prefix}alert-color);
  background-color: var(--#{$prefix}alert-bg);
  border: var(--#{$prefix}alert-border);
  @include border-radius(var(--#{$prefix}alert-border-radius));
}

// Headings for larger alerts
.alert-heading {
  // Boosted mod: ensure headings look like paragraphs
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  // End mod
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $alert-dismissible-padding-r;

  // Adjust close link position
  .btn-close {
    position: absolute;
    top: $alert-btn-close-offset; // Boosted mod
    right: $alert-btn-close-offset; // Boosted mod
    z-index: $stretched-link-z-index + 1;
  }
}


// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert.

@each $state, $value in $alert-colors {
  $alert-background: transparent; // Boosted mod
  $alert-border: $alert-border-width solid $value; // Boosted mod
  // Boosted mod: no $alert-color change

  // Boosted mod: no contrast-ratio test
  .alert-#{$state} {
    @include alert-variant($alert-background, $alert-border, $alert-color, map-get($alert-icons, $state)); // Boosted mod
  }
}
// scss-docs-end alert-modifiers

//
// Boosted mod
//

//// Additional content
.alert * p {
  margin: 0;
}

.alert-heading ~ p {
  font-weight: $font-weight-normal;
}

//// Icons
.alert-icon {
  flex-shrink: 0;
  width: $alert-icon-size;
  margin: -#{$alert-icon-margin-y * .5} 0;

  + * {
    margin: $alert-icon-margin-y 0;
  }

  &::before {
    display: block;
    order: -1;
    height: $alert-logo-size;
    content: "";
    background-repeat: no-repeat;

    /* rtl:raw:
    background-position: top right;
    */
    background-size: $alert-logo-size;
  }
}

//// Small alerts
.alert-sm {
  // scss-docs-start alert-sm-css-vars
  --#{$prefix}alert-padding-x: 0;
  --#{$prefix}alert-padding-y: #{$alert-padding-sm};
  --#{$prefix}alert-margin-bottom: 0;
  --#{$prefix}alert-border: 0;
  // scss-docs-end alert-sm-css-vars

  font-size: $font-size-sm;
  line-height: $line-height-sm;

  .alert-icon {
    width: $alert-icon-size * .5;
    margin: $alert-icon-margin-y 0;

    &::before {
      height: $alert-logo-size-sm;
      background-size: $alert-logo-size-sm;
    }
  }

  .btn-close {
    --#{$boosted-prefix}icon-spacing: #{$btn-close-padding-sm};
    top: $alert-btn-close-offset-sm;
    right: -$alert-btn-close-offset-sm;
  }
}
// End mod
